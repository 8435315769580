'use client'

// https://nextjs.org/docs/app/api-reference/file-conventions/not-found

import * as Sentry from '@sentry/nextjs'
import ErrorPage from '@astrolab/web/common/Error/ErrorPage'
import { useEffect } from 'react'
import { CacheProvider } from '@chakra-ui/next-js'
import { ChakraProvider } from '@chakra-ui/react'
import theme from '@astrolab/web/theme/theme'
import { usePathname } from 'next/navigation'

export default function NotFound({
  error,
}: {
  error: Error & { digest?: string }
}) {
  const currentPath = usePathname()
  useEffect(() => {
    Sentry.captureMessage(`Page ${currentPath} is not found`)
  }, [error, currentPath])

  return (
    <html>
      <body>
        <CacheProvider>
          <ChakraProvider theme={theme}>
            <ErrorPage message="This page could not be found." />
          </ChakraProvider>
        </CacheProvider>
      </body>
    </html>
  )
}
